// DatePickerComponent.js
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LeftArrowRes from '@/textures/left_arrow.png'; // Ensure the path is correct
import RightArrowRes from '@/textures/right_arrow.png'; // Ensure the path is correct
import '@/components/css/vehicle_page/VehiclePageInfo.css';
import '@/components/css/Scrollable.css';
import Button from 'react-bootstrap/Button';
import Calendar from '@/textures/calendar.png';
import { forwardRef } from 'react';

const DatepickerStartInput = forwardRef((props, ref) => (
  
  <Button
    ref={ref}
    className="date-picker"
    variant="success"
    style={{
      backgroundColor: 'transparent',
      border: 'none',
      display: 'flex',
      alignItems: 'flex-end',
    }}
    onClick={props.onClick}
  >
    <img
      style={{ height: '25px' }}
      src={Calendar}
      alt="img"
    />
    {props.value}
  </Button>
));

const DatePickerComponent = ({ dateStart, setStartDate, addDate, dutyDateList, calendarContainer }) => {
  if (!dutyDateList || !Array.isArray(dutyDateList)) {
    console.error('dutyDateList 格式不正確');
    return null;
  }

  const formattedDutyDateList = dutyDateList.map(date => new Date(date));

  return (
    <div className="driver-page-info datepicker-row">
      <button
        type="button"
        className="driver-page-info left-arrow"
        onClick={() => addDate(-1)}
      >
        <img src={LeftArrowRes} width="24" height="24" alt="prevday" />
      </button>
      <div className="vehicle-page-info datepicker-column">
        <DatePicker
          selected={dateStart}
          onChange={setStartDate}
          selectsStart
          highlightDates={formattedDutyDateList}
          calendarContainer={calendarContainer}
          customInput={<DatepickerStartInput />}
          // Include any custom styles or functionality here if necessary
        />
        
      </div>
      <button
        type="button"
        className="vehicle-page-info right-arrow"
        onClick={() => addDate(1)}
      >
        <img src={RightArrowRes} width="24" height="24" alt="nextday" />
      </button>
    </div>
  );
};

DatePickerComponent.propTypes = {
  dateStart: PropTypes.instanceOf(Date).isRequired,
  setStartDate: PropTypes.func.isRequired,
  addDate: PropTypes.func.isRequired,
  dutyDateList: PropTypes.array.isRequired,
};

export default DatePickerComponent;
